


























































import { Notify } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import List from '@/components/ShareComponent/PullDownUp.vue'
import MyCom from '@/components/ExpressComponent/MyExpressComponent.vue'
import UpDown from '@/components/ShareComponent/ListBottom.vue'
import Stroage from "@/util/Storage"
import { MyComponentType } from '@/Type/index'
import { StoreExpress } from '@/store/modules/Express/Express'
import { MyUpDataType } from '@/Api/Express/interfaceType'
import { MyExpress as MyExpressList } from "@/Api/Express/index"
import { Vue,Component,Watch } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface MyExpressType<T=string>{
    loadingShow:boolean;
    RightIcon:T;
    userId:T|number;
    token:T;
    NavShow:boolean;
    NavIndex:number;
    pullDown:boolean;
    upDown:boolean;
    NavList:Array<{ title:T,state:number }>;
    List: MyComponentType[]
    UpData:MyUpDataType;
    stopUpDown:boolean;
    ScrollNum:number;

    initData():void;
    handleNavShow():void;
    handleClickNav(data:{ title:T,state:number },index?:number,bool?:boolean):void;
    handlePullDown(data:any):void;
    handleUpDown(data:any):void;
    handleUpData(bool?:boolean):void;
    handleReftch(data?:any):void;
    handleError(data:string):void;
}

@Component({ name:"MyExpress",components:{ List,MyCom,UpDown,HeadTop } })
export default class MyExpress extends ZoomPage implements MyExpressType{
   loadingShow = true;
   userId: string|number = ""
   token = ""
   RightIcon = require("$icon/Basics/Right.png");
   NavShow = false;
   NavIndex = 0;
   NavList = [
       { title:"全部",state:-1 },
       { title:"待支付",state:0 },
       { title:"待接单",state:1 },
       { title:"待配送",state:3 },
       { title:"已完成",state:4 },
       { title:"异常订单",state:5 },
       { title:"其他",state:6 },
   ]
   List: MyComponentType[] = []
   UpData:MyUpDataType = {
        pageNo:1,
        pageSize:15
    }
    pullDown = false;
    upDown = true;
    stopUpDown = false;
    ScrollNum = 0;

   get GetNavList(){
       if( this.NavList.length > 4 ){
           let List:any = []
           for( let i = 0; i < 5; i ++ ){
               List.push( this.NavList[ i ] )
           }
           return List
       }else{
           return this.NavList
       }
   }

   mounted() {
       this.loadingShow = false
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       // 初始化
       this.initData()
   }

   initData(){
       this.UpData = StoreExpress.GetMyType
       if( this.UpData.status ){
           let Index:number = this.NavList.findIndex( a=>a.state == this.UpData.status)
           if( Index < 0 )Index = 0;
           this.NavIndex = Index
       }
       if( StoreExpress.GetMyList.length ){
            this.List = StoreExpress.GetMyList
            this.loadingShow = false
            this.upDown = false
            // 滚动条 数值
            this.ScrollNum = StoreExpress.GetExpScroll.MyScroll as number
       }else{
           this.handleUpData()
       }
   }

   handleNavShow(){
       this.NavShow = !this.NavShow
       if( this.NavShow ){
           document.body.style.overflow = "hidden"
       }else{
           document.body.style.overflow = "auto"
       }
   }

   handleClickNav(data:{ title:string,state:number },index:number,bool?:boolean){
       if( data.state < 0 ){
           this.NavIndex = 0
           this.UpData.pageNo = 1
           delete this.UpData.status
           this.upDown = true
           this.stopUpDown = false
           if( !bool ){
               this.handleNavShow()
           }
           this.handleUpData(true)
           return
       }
       if( this.NavIndex !== index ){
           this.NavIndex = index
           this.UpData.pageNo = 1
           this.UpData.status = data.state
           this.upDown = true
           this.stopUpDown = false
           if( !bool ){
               this.handleNavShow()
           }
           this.handleUpData(true)
       }
   }

   handlePullDown(data: any): void {
       this.pullDown = false
       this.stopUpDown = false
       this.UpData.pageNo = 1
       MyExpressList({
           userId:this.userId,
           token:this.token
       },this.UpData).then( (res:any)=>{
           this.pullDown = true
           if( res.message.code === "200" ){
               if( res.data.length < this.UpData.pageSize ){
                   this.stopUpDown = true
               }else{
                   this.UpData.pageNo += 1
               }
               this.List = res.data
           }else{
               this.handleError(res.message.msg)
           }
       } )
   }
   handleUpDown(data: any): void {
       if( this.stopUpDown )return;
       this.UpData.pageNo += 1
       this.upDown = true
       this.handleUpData()
   }
   handleUpData(bool?: boolean): void {
       MyExpressList({
           userId:this.userId,
           token:this.token
       },this.UpData).then( (res:any)=>{
           this.loadingShow = false
           this.upDown = false
           console.log( res )
           if( res.message.code === "200" ){
               if( res.data.length < this.UpData.pageSize ){
                   this.stopUpDown = true
               }
               if( bool ){
                   this.List = res.data
               }else{
                   this.List = this.List.concat( res.data )
               }
           }else{
               this.handleError(res.message.msg)
           }
       } )
   }

   handleReftch(data?:any){
       this.UpData.pageNo = 1
       this.loadingShow = true
       this.handleUpData(true)
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

   @Watch("List")
   handleList(newVal:any){
       StoreExpress.SetMyList( newVal )
   }

   @Watch("UpData")
   handleUpDataChange(newVal:any){
       StoreExpress.SetMyType( newVal )
   }
}
